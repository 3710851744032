.react-datepicker {
  font-family: unset;
  font-size: 0.75rem;
  border-color: var(--chakra-colors-grey-500);
  background: none;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  border-bottom-color: var(--chakra-colors-grey-500) !important;
  border-top-color: var(--chakra-colors-grey-500) !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 40px;
  color: var(--chakra-colors-blue-500);
}

.react-datepicker__header {
  background-color: var(--chakra-colors-grey-500);
  border-color: var(--chakra-colors-grey-700);
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--chakra-colors-grey-100);
}

.react-datepicker__month {
  background-color: var(--chakra-colors-grey-900);
  margin: 0;
  padding: 0.4rem;
  min-height: 13rem;
}

.react-datepicker__day,
.react-datepicker__day-name {
  color: var(--chakra-colors-grey-100);
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-grey-600);
}

.react-datepicker__day--outside-month {
  color: var(--chakra-colors-grey-400);
}

.react-datepicker__day--selected,
.react-datepicker__month-text--selected {
  background: var(--chakra-colors-blue-500);
}
