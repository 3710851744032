::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  background-color: var(--chakra-colors-grey-900);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-blue-500);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--chakra-colors-blue-900);
}
